import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import HeaderLogo from "../images/pipp-header-logo.svg";
import contactHeaderSvg from "../images/CONTACT.svg"
import { Link } from "gatsby"

const ListLink = (props) => (
  <li className="header-list-element">
    <Link to={props.to} className="header-list-link">
      {props.children}
    </Link>
  </li>
);

export default () => (
  <Layout>
    <SEO title="Contact" />
    <header className="nav-header">
      <nav>
        <Link className="logo-link" to="/">
          <img src={HeaderLogo} alt="Home" />
        </Link>
        <div className="heading-container">
          <ul className="header-ul">
            <ListLink to="/about">about</ListLink>
            <ListLink to="/manifesto">manifesto</ListLink>
            <ListLink to="/upcoming">upcoming</ListLink>
            <ListLink to="/past">past</ListLink>
            <ListLink to="/resources">resources</ListLink>
            <ListLink to="/contact">contact</ListLink>
          </ul>
          <img className="heading-img" src={contactHeaderSvg} alt="About" />
        </div>
      </nav>
    </header>
    <main className="contact-main">
      <address>e-mail: hello [ at ] louisamerry [ dot ] com</address>
    </main>
  </Layout>
);
